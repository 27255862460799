/**
 * @module FindsSearchBox
 */

// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';
import debounce from 'lodash/debounce';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import YellowSearchIcon from '../../assets/yellow-search.svg';
import GreySearchIcon from '../../assets/grey-search.svg';
import './FindsSearchBox.scss';

const FindsSearchBox = React.forwardRef((props, ref) => {
  const { user } = useAuth();
  const { isFullSearch = false, theme = 'dark-mode', setSearchInput } = props;
  const { query, refine } = useSearchBox(props);
  const { results, status } = useInstantSearch();
  const [inputValue, setInputValue] = React.useState(query);
  const [isSearchOpen, setIsSearchOpen] = React.useState(isFullSearch);
  const [searchStarted, setSearchStarted] = React.useState(false);
  const inputRef = React.useRef(null);

  const isSearchStalled = status === 'stalled';

  /**
   * Convenience function to call Segment analytics.
   *
   * @param {object} params - The function params object.
   * @param {string} params.action - The action value for the analytics  call.
   * @param {Event} params.event - The Event object associated with the analytics call.
   * @param {string} params.eventName - The event name to send with the analytics call.
   * @param {string} [params.label] - Optional label value.
   * @param {string} [params.value] - Optional value attribute.
   * @param {object} [params.additionalProperties] - Optional object with additional properties.
   */
  function callAnalytics({
    action,
    event,
    eventName,
    label,
    value,
    additionalProperties,
  }) {
    callSegmentTrack({
      event: eventName,
      properties: {
        action,
        component: 'Finds Search Box',
        component_url: null, // No URLs associated with this component to track.
        label: label || event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value,
        ...additionalProperties,
      },
    });
  }

  /**
   * Handler function to handle Search Start and Search Submit tracking.
   *
   * @param {boolean} isStarted - Boolean flag that indicates whether or not the search is started or submitted.
   * @param {string} value - The search input value.
   */
  function handleSearchTrack(isStarted, value) {
    callSegmentTrack({
      event: isStarted ? EVENTS.searchStarted : EVENTS.searchSubmitted,
      properties: {
        eventType: 'view',
        form_fields: {
          search: value,
        },
        form_name: 'Search',
        index: results?.index,
        logged_in: !!user,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  // Memoized debounced refine function.
  const debouncedRefine = React.useMemo(
    () =>
      debounce((value) => {
        refine(value);
        if (setSearchInput && typeof setSearchInput === 'function') {
          setSearchInput(value);
        }
        if (value) {
          handleSearchTrack(false, value);
        }
      }, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refine, setSearchInput],
  );

  /**
   * Convenience effect to clean up debounced function on unmount.
   */
  React.useEffect(() => {
    return () => {
      debouncedRefine.cancel();
    };
  }, [debouncedRefine]);

  function setQuery(newQuery) {
    if (!searchStarted) {
      setSearchStarted(true);
      handleSearchTrack(true, newQuery);
    }
    setInputValue(newQuery);
    debouncedRefine(newQuery);
  }

  /**
   * Handler function for search icon click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function searchIconClick(event) {
    callAnalytics({
      action: ACTIONS.clicked,
      event,
      eventName: EVENTS.buttonAction,
      label: 'Search Icon',
    });
    if (!isFullSearch) {
      setIsSearchOpen(!isSearchOpen);
    }
  }

  /**
   * Handler function for form reset event.
   */
  const handleReset = () => {
    callAnalytics({
      action: ACTIONS.clicked,
      eventName: EVENTS.buttonAction,
      label: 'Reset',
    });
    if (inputRef.current) {
      setQuery('');
    }
  };

  React.useImperativeHandle(ref, () => ({
    handleReset,
  }));

  return (
    <div
      className={`search-box fadeInOut ${!isSearchOpen && 'closed'} ${
        isFullSearch && 'full-search'
      } ${theme}`}
    >
      <form
        action=""
        className="fadeInOut"
        noValidate={true}
        onReset={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setQuery('');
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        role="search"
      >
        <div className="input-container">
          <button
            className="search-button"
            disabled={isFullSearch}
            onClick={searchIconClick}
          >
            <img
              alt="Search Icon"
              className="icon"
              src={theme === 'dark-mode' ? YellowSearchIcon : GreySearchIcon}
            />
          </button>
          <input
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            className={`fadeInOut input ${!isSearchOpen && 'hidden'}`}
            maxLength={512}
            onChange={(event) => {
              setQuery(event.currentTarget.value);
            }}
            placeholder="Search for anything"
            ref={inputRef}
            spellCheck={false}
            type="search"
            value={inputValue}
          />
          <span hidden={!isSearchStalled}>Searching...</span>
        </div>
      </form>
    </div>
  );
});

FindsSearchBox.displayName = 'Finds Search Box';

export default FindsSearchBox;
